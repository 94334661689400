// SEE
// https://console.firebase.google.com/project/itupeli/storage/itupeli.appspot.com/files/~2Fitu~2Fdefaults~2Fdefaults-2022-04-01~2Fpdfs

const startPdf                      = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fpdfs%2FFriCo%20aloitus.pdf?alt=media&token=7835a4a9-648a-47db-8177-b6642f76df55"
// OLD
// const endPdf                        = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fpdfs%2Flopetus.pdf?alt=media&token=0ffbd942-8f6e-417a-bacd-68766801b8dc"

// NEW
// YEAR 2024
const endPdf                        = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fpdfs%2FFriCo%20lopetus%20-%20lowres%202024.pdf?alt=media&token=bf2bb34a-2407-49ac-8d70-7f565be54a1b"

//const gameDefaultImage_FI           = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbanners%2Fbanner_FI.jpg?alt=media&token=a5a0c2e9-ae60-47e9-aab6-c6099302e96e"
//const gameDefaultImage_EN           = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbanners%2Fbanner_EN.jpg?alt=media&token=bb886c0e-e11d-48e7-9ac9-aaf4c15245b3"

//DEFAULT GAME BANNERS
const gameBanner_FI                 = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FGame%20default%20banners%2FPeopleBanner_FI.png?alt=media&token=7c891aeb-8c15-4511-9786-a6b171887da5"
const gameBanner_EN                 = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FGame%20default%20banners%2FPeopleBanner_EN.png?alt=media&token=021804b3-fa50-4b88-9340-d83845fca05c"
const gameBanner_VAJO               = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FGame%20default%20banners%2FPeopleBanner_VAJO.png?alt=media&token=de02f15b-e5e6-4687-af51-f5b876afbc57"

const startPdf_EN                   = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fpdfs%2FFriCo%20aloitus_EN_small.pdf?alt=media&token=b46a119d-8bec-42be-9901-e09a1e121bbb"
const endPdf_EN                     = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fpdfs%2FFriCo%20lopetus_EN_small.pdf?alt=media&token=4aa514d7-df2c-4f52-b0bc-13a0a669448d"

const startPdf_VAJO                 = ""
const endPdf_VAJO                   = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2FITU-Valmentava%20johtaminen%20outlined%20fonts.pdf?alt=media&token=5c5b9ffd-2fb3-42ea-8cbc-41ff277f5a86"

const startPdf_VH                   = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fpdfs%2FVH-FriCo%20aloitus.pdf?alt=media&token=feff037a-3e4f-48ee-b10e-7232d4692c10"
const endPdf_VH                     = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fpdfs%2FVH-FriCo%20lopetus.pdf?alt=media&token=a44ae8c0-10d5-48bf-9655-f5696dc6ac33"

const defaultStartImage             = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Flobby-and-library%2Fcastle-10x1920.jpg?alt=media&token=7d9b0f99-5642-41eb-aa39-678673a7bba2";
const defaultEndimage               = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Ftesting-ahaa%2Fuserdata%2FB8RAjJPcWggC3nzos1sIMJZVJEX2%2Fb41e80c6-9612-4e04-ad6f-483e216f2d07__garden-07.png?alt=media&token=2e06c71a-d664-4fa3-8f43-0dfc54dd9d1f";
                                       
const defaultLobbyBgImage           = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Flobby-and-library%2Flobby-01x1920.jpg?alt=media&token=4d20f2d1-58e3-4299-abe3-7bd4b656287c";
const defaultRockyPathBgImage       = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Flobby-and-library%2Flibrary-01x1920.jpg?alt=media&token=a4a257ba-e755-4e75-b1d9-7fdf143a1228";

const defaultCardFront              = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2Fcard-text.svg?alt=media&token=710bbf22-592c-4911-b647-44be4bb3a287"
const defaultcardBackground         = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2Fcard-key.svg?alt=media&token=4720a0e6-e735-4cfc-8af7-c5d5c3e3c1e9"
const cardFrontFiftyFifty           = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2Fcard-FiftyFifty.svg?alt=media&token=0489623f-6c73-46af-84d5-5607dc16802a"
const cardFrontFiftyFifty_EN        = "https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ffifty-fifty-EN.png?alt=media&token=0a73fcba-4629-4718-a1e7-8e96d6fa97a3"

const taskEmpty                     = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-empty.png?alt=media&token=917bc436-5a62-4488-8982-c51522fbeee0'
const taskEmptyDecoration           = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftast-empty-decoration.png?alt=media&token=f4ab67cf-fd81-4584-ae77-449cb2369dcf'
const taskPen8                      = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-pen-8.png?alt=media&token=de367231-81d0-492b-aca0-1dbee0adfbdf'
const taskPie                       = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-pie.png?alt=media&token=6e21f3e3-8650-4818-9c75-967d74c6f306'
const taskSmiley                    = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-smiley.png?alt=media&token=7e52f4dd-8fae-47da-bc9e-a54d788b0e46'
const taskTwoSmiley                 = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-two-smileys.png?alt=media&token=ed980394-a371-4a61-a233-828897d9cfff'
// const taskSpell                  = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-spell.png?alt=media&token=39a35578-2c9c-4d8f-a4c6-ff6bea3b32f4'
const taskSpell                     = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-spell.png?alt=media&token=e9c9cc25-caec-498c-92f1-634de4937ad8'
const taskSpell_en                  = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Fspell_en.png?alt=media&token=668cd9e0-054f-42fd-baf3-554060abd9c2'
const taskThrone                    = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-throne.png?alt=media&token=805e8235-cadf-4771-b917-5d5eed11de35'

// VAJO
const taskVAJO_01                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fcard-front-01.png?alt=media&token=5d26a1d7-5be5-4e3d-b7a0-501bd6b4a97c'
const taskVAJO_02                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fcard-front-07.png?alt=media&token=5139d9c3-d925-4545-9757-87a4f5f6fb81'
const taskVAJO_03                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fcard-front-03.png?alt=media&token=fa80396f-c213-42c3-9565-9d0519f4b995'
const taskVAJO_04                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fcard-front-02.png?alt=media&token=16e25566-e447-4152-9a0d-c40b4bf8be4e'
const taskVAJO_05                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fcard-front-05.png?alt=media&token=18e545e8-ef94-4de6-bc2b-3123743e041a'
const taskVAJO_05_light_bulb        = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Flobby-and-library%2Fcard-front-light-bulbx3.png?alt=media&token=e8de63c6-1426-4276-8c7d-a7c6566dd250'
// stunning rock
const taskVAJO_06                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fstunning-stone-1.png?alt=media&token=d8cbf586-4d1c-4821-a855-30e5a5f08c58'
const taskVAJO_07                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fcard-front-02.png?alt=media&token=16e25566-e447-4152-9a0d-c40b4bf8be4e'
const taskVAJO_08                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fcard-front-08.png?alt=media&token=948d6f11-f22d-4a65-aa5f-1bea7fc6ecdc'

// Tax administration
const skill_VH_01                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2FSkill-cards-with-skill-names-01-for-SVG_01-kuunteleminen.svg?alt=media&token=523386e3-88d6-4557-a21c-8a5f593df462'
const skill_VH_02                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2FSkill-cards-with-skill-names-01-for-SVG_02-kysyminen.svg?alt=media&token=7017e753-c433-4feb-b31c-f509dd51ec09'
const skill_VH_03                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2FSkill-cards-with-skill-names-01-for-SVG_03-konkreettisessa%20pysyminen.svg?alt=media&token=ce07385b-df4d-42c9-b10d-dc3e7bf9f798'
const skill_VH_04                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2F04-rakentavan%20dialogin%20yll%C3%A4pito.svg?alt=media&token=a7723712-04c5-483e-aed0-ac1030ef4092'
const skill_VH_05                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2FSkill-cards-with-skill-names-01-for-SVG_05-neuvojen%20ja%20oletusten%20v%C3%A4ltt%C3%A4minen.svg?alt=media&token=08ba4d1c-0c72-487d-b010-915b0e432d7e'
// svg
const skill_VH_06                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2F06-peilaaminen.svg?alt=media&token=ff069deb-76af-41fd-b82c-5572597a5ca1'
// jpg
//const skill_VH_06                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2F06-peilaaminen-1x.jpg?alt=media&token=6a3d41cf-b11e-4de5-8920-e79f80f7d794'
const skill_VH_07                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2FSkill-cards-with-skill-names-01-for-SVG_07-empaattinen%20l%C3%A4sn%C3%A4olo.svg?alt=media&token=29280286-dfff-4e01-87bb-f97ab8660531'
const skill_VH_08                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fcards%2FSkill-cards-with-skill-names-01-for-SVG_08-vuorovaikutustilanteen%20johtaminen.svg?alt=media&token=6db60cc6-e398-4f5c-8e59-a6a807120bf2'


const CoachManagerLeader            = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2FVAJO%2Fcard-front-06.png?alt=media&token=69825056-f22f-42f7-857c-ace0d7306a51'

const bannerMixed                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbanners%2Fbanner-mixed.png?alt=media&token=f4770fce-3342-4391-8c90-3b39f0c9903d';
const bannerYoung                   = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbanners%2Fbanner-young.png?alt=media&token=b9365370-cba2-41ef-8b24-786d79c6fe22';

const userAvatar                    = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbanners%2Fuser.png?alt=media&token=b2dc39c4-b6ea-42f4-b936-521e36d7bec2'

const taskHartArrows                = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Ftasks%2Ftask-hart-arrows.png?alt=media&token=dc22fd24-2816-43ba-86ed-71597bba2d0f'

export const defaultTaskImagesArray = [ taskEmpty, taskPen8, taskPie, taskSmiley, taskTwoSmiley, taskSpell, taskThrone, taskHartArrows ];
export const defaultBannerArray     = [ bannerMixed,  bannerYoung];
export const defaultUserAvatarArray = [ userAvatar ];

const bookIdle                      = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-00-IDLE.mp4?alt=media&token=39a456fa-4fd2-4e09-81b5-47ce9e88b3d2'
const book1                         = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-01.mp4?alt=media&token=47129fb6-a208-45e2-b644-f582652dfdca'
const book2                         = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-02.mp4?alt=media&token=fe504fcf-25ea-429d-a09c-7e98d894ed77'
const book3                         = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-03.mp4?alt=media&token=c94c05f3-6380-49a6-b33f-5aeb442f7392'
const book4                         = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-04.mp4?alt=media&token=14cdb65d-fc81-4bd6-bb10-8eb823939d36'
const book5                         = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-05.mp4?alt=media&token=993e9aba-6390-439d-8517-93a7edaeb63f'
const book6                         = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-06.mp4?alt=media&token=8d6bd990-e5a8-4f2a-93f5-cdcb664bf5ef'
const book7                         = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-07.mp4?alt=media&token=2d9c4c00-25c2-416d-831c-f3f0c3d6efe2'
const book8                         = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Fdefaults-2022-04-01%2Fbooks%2FBOOK-08.mp4?alt=media&token=0352827b-66da-473c-ab53-078c6531a605'

const book_hart_arrows              = 'https://firebasestorage.googleapis.com/v0/b/itupeli.appspot.com/o/itu%2Fdefaults%2Flobby-and-library%2Flib-hart-arrows.svg?alt=media&token=8dc54a48-5bef-4578-ab39-21147b25da73'

const games = [];


games[0] = {
    data :
        {
            gameLang:                       "FI",
            name:                           "FriCo-mallipeli 2024",
            seoImage:                       gameBanner_FI,
            text_ObservationInCastle:       "Havaintojani linnassa",
            wordTitle:                      "Ammatillinen vuorovaikutus – ensipainos",
            wordIntro:                      "Yhteisellä matkalla Tieteentekijöiden linnassa tehtyjä huomioita ammatillisesta vuorovaikutuksesta:",
            wordAction:                     "Luo Word-dokumentti",

            version:        2,
            started:        false,
            smileys:        [],
            userComments1:  [],
            userComments2:  [],
            description:    "Kirjoita lyhyt kuvaus",
            startImage:     defaultStartImage,   
            endImage:       defaultEndimage,
            //gameDefaultImage: gameDefaultImage_FI,
            pdfs:           [ 
                                {
                                    url:startPdf,
                                    name: "Aloitus PDF"
                                },
                                {
                                    url:endPdf,
                                    name: "Lopetus PDF"
                                },
                            ],
            slider:         {
                                questionText:"Mitä mieltä olet väittämästä:", 
                                minText:"täysin eri mieltä",
                                maxText:"täysin samaa mieltä",
                                players: [],
                                average: 0,
                                avatarScale : 40,
                            },
            garden:         {
                                pageTitle: "Havaintojani linnassa:"
                            },                            

            // cardsLobby:{
            //     bgImage:            defaultLobbyBgImage,
            //     cardBackground:     defaultcardBackground,
            //     imageCount:         8,
            
            //     1:          {
            //                     img:    defaultCardFront,
            //                     text:   'Mikä seuraavista on alin kuuntelun taso? <br/>Tietäjä, Tuomari, Kuuro, Tulkitsija, Ymmärtäjä?',
            //                     done: false,
            //                 },
            //     2:          {
            //                     img:    defaultCardFront,
            //                     text:   '<b>Kysyminen vuorovaikutustilanteissa:</b> <br>'+ 
            //                                 '• osoittaa kiinnostusta <br>' +
            //                                 '• lisää ymmärrystä<br>' +
            //                                 '• mitä muita hyötyjä olet havainnut?',
            //                     done: false,
            //                 },
            //     3:          {
            //                     img:    defaultCardFront,
            //                     text:   'Mistä tunnistaa hyvän ja huonon vuoropuhelun?',
            //                     done: false,
            //                 },
            //     4:          {
            //                     img:    cardFrontFiftyFifty,
            //                     text:   '<b>Mitä tarkoitetaan peilaamisella vuorovaikutuksessa?</b> <br>'+ 
            //                                 'A) puheen tarkentamista toistamalla mitä on kuultu <br>' +
            //                                 'B) laittautumista vuorovaikutustilanteeseen <br>' +
            //                                 'C) tapaa varmistaa, että on ymmärtänyt oikein toisen puhetta <br>' +
            //                                 'D) pyytää keskustelukumppania katsomaan peiliin',
            //                     done: false,
            //                 },
            //     5:          {
            //                     img:    defaultCardFront,
            //                     text:   '”Luulen, että ne...”<br/>Mihin oletukset voivat vuorovaikutuksessa johtaa?',
            //                     done: false,
            //                 },
            //     6:          {
            //                     img:    defaultCardFront,
            //                     text:   'Oletko kokeillut viestien tarkentamista konkreettisten esimerkkien kautta?',
            //                     done: false,
            //                 },
            //     7:          {
            //                     img:    defaultCardFront,
            //                     text:   'Empaattisen läsnäolon ilmeneminen vuorovaikutustilanteessa, kokemuksia?',
            //                     done: false,
            //                 },
            //     8:          {
            //                     img:    cardFrontFiftyFifty,
            //                     text:   '<b>Vuorovaikutustilanteen ohjaaminen on </b><br>' +
            //                                 'A) minielokuvan tekemistä <br>' +
            //                                 'B) tavoitteiden mukaisesta etenemisestä huolehtimista <br>' +
            //                                 'C) vuorovaikutustilanteen rakenteellisista tekijöistä huolehtimista <br>' +
            //                                 'D) tapa saada oma ajatus läpi' ,
            //                     done: false,
            //                 },

            // },
            
            // Above questions replaced on 7.2.2024 by the following:
            cardsLobby:{
                bgImage:            defaultLobbyBgImage,
                cardBackground:     defaultcardBackground,
                imageCount:         8,


                1:          {
                                img:    skill_VH_01,
                                text:   'Mikä seuraavista on alin kuuntelun taso? <br/>Tietäjä, Tuomari, Kuuro, Tulkitsija, Ymmärtäjä?',
                                done: false,
                            },
                2:          {
                                img:    skill_VH_02,
                                text:   '<b>Mitä, missä, miten, kuka, koska, kuinka?</b> <br>'+ 
                                            'Kuvaa kysymisen hyötyjä',
                                done: false,
                            },
                3:          {
                                img:    skill_VH_04,
                                text:   'Mistä tunnistaa hyvän ja huonon vuoropuhelun?',
                                done: false,
                            },
                4:          {
                                img:    skill_VH_06,
                                text:   '<b>Mitä tarkoitetaan peilaamisella vuorovaikutuksessa?</b> <br>'+ 
                                            'A) puheen tarkentamista toistamalla mitä on kuultu <br>' +
                                            'B) laittautumista vuorovaikutustilanteeseen <br>' +
                                            'C) tapaa varmistaa, että on ymmärtänyt oikein toisen puhetta <br>' +
                                            'D) pyytää keskustelukumppania katsomaan peiliin',
                                done: false,
                            },
                5:          {
                                img:    skill_VH_05,
                                text:   '”Luulen, että ne...”<br/>Mihin oletukset voivat vuorovaikutuksessa johtaa?',
                                done: false,
                            },
                6:          {
                                img:    skill_VH_03,
                                text:   'Oletko kokeillut viestien tarkentamista konkreettisten esimerkkien kautta?',
                                done: false,
                            },
                7:          {
                                img:    skill_VH_07,
                                text:   'Empaattisen läsnäolon ilmeneminen vuorovaikutustilanteessa, kokemuksia?',
                                done: false,
                            },
                8:          {
                                img:    skill_VH_08,
                                text:   '<b>Vuorovaikutustilanteen ohjaaminen/johtaminen on </b><br>' +
                                            'A) minielokuvan tekemistä <br>' +
                                            'B) tavoitteiden mukaisesta etenemisestä huolehtimista <br>' +
                                            'C) vuorovaikutustilanteen rakenteellisista tekijöistä huolehtimista <br>' +
                                            'D) tapa saada oma ajatus läpi' ,
                                done: false,
                },

            },


            cardsLibrary:{
                bgImage:            'not-defined',
                cardBackground:     'not-defined',
                booksCount:         8,
                openedBooks: { "1":false, "2":false, "3":false, "4":false, "5":false, "6":false, "7":false, "8":false, },
                books : [ 
                    {
                        id:0, 
                        book:bookIdle, 
                        question:"idle",
                        done: false,
                    },
                    {
                        id:1,
                        place:2, 
                        book:book2, 
                        question:"Mieti tilanne, jossa olet ollut innostunut käsillä olevasta tehtävästä. Millä tavalla innostuneisuus näkyi toiminnassasi?", 
                        task:taskSmiley,
                        done: false,
                    }, 
                    {
                        id:2,
                        place:3, 
                        book:book3, 
                        question:"Mihin työyhteisössä tarvitaan vuorovaikutusta?", 
                        task:taskPen8,
                        done: false,
                    }, 
                    {
                        id:3, 
                        place:6, 
                        book:book6, 
                        question:"Miten ottaa toisen tiimin kanssa puheeksi tiedonkulun ongelmia?", 
                        task:taskPie,
                        done: false,
                    },
                    {
                        id:4, 
                        place:7, 
                        book:book7, 
                        question:"", 
                        task:taskThrone,
                        done: false,
                    }, 
                    {
                        id:5,
                        place:1, 
                        book:book1, 
                        question:"Miten toimia tilanteessa, jossa henkilö esittää asiansa huutamalla?", 
                        task:taskTwoSmiley,
                        done: false,
                    }, 
                    {
                        id:6, 
                        place:4, 
                        book:book4, 
                        question:"Palautteen antaminen ja vastaanottaminen ovat tärkeä osa ammatillista vuorovaikutusta. Miten tätä taitoa voisi työyhteisössä kehittää?", 
                        task:taskPie,
                        done: false,
                    }, 
                    {
                        id:7, 
                        place:5, 
                        book:book5, 
                        question:"", 
                        task: taskSpell,
                        done: false,
                    },
                    {
                        id:8, 
                        place:8, 
                        book:book8, 
                        question:"Kollegasi on alakuloinen. Hän on mielestään epäonnistunut tärkeässä asiassa. <br />Miten autat häntä käsittelemään asiaa?", 
                        task:taskTwoSmiley,
                        done: false,
                    }
                ],
            },

        
        }
}    


games[1] = {
    data :
        {
            gameLang:                       "EN",
            name:                           "FriCo-game",
            seoImage:                       gameBanner_EN,
            text_ObservationInCastle:       "My observations in the castle:",
            wordTitle:                      "Professional Communication – First Edition",
            wordIntro:                      "Summary of observations during our adventures in the Scientists' Castle:",
            wordAction:                     "Create a Word document",

            version:        2,
            started:        false,
            smileys:        [],
            userComments1:  [],
            userComments2:  [],
            description:    "Kirjoita lyhyt kuvaus",
            startImage:     defaultStartImage,   
            endImage:       defaultEndimage,
            //gameDefaultImage: gameDefaultImage_EN,
            pdfs:           [ 
                                {
                                    url:startPdf_EN,
                                    name: "Aloitus PDF"
                                },
                                {
                                    url:endPdf_EN,
                                    name: "Lopetus PDF"
                                },
                            ],
            slider:         {
                                questionText:"What do you think about the statement?", 
                                minText:"Completely agree",
                                maxText:"Completely disagree",
                                players: [],
                                average: 0,
                                avatarScale : 40,
                            },
            garden:         {
                                pageTitle: "Havaintojani linnassa:"
                            },                            


            cardsLobby:{
                bgImage:            defaultLobbyBgImage,
                cardBackground:     defaultcardBackground,
                imageCount:         8,
            
                1:          {
                                img:    defaultCardFront,
                                text:   "Do you recognize the levels of listening?<br/> Deaf, Knower, Judge, Interpreter, Understander",
                                done: false,
                            },
                2:          {
                                img:    defaultCardFront,
                                text:   '<b>Asking during a conversation:</b> <br>'+ 
                                            '• shows interest <br>' +
                                            '• increases understanding<br>' +
                                            '• what other benefits have you noticed?',
                                done: false,
                            },
                3:          {
                                img:    defaultCardFront,
                                text:   "How do you recognize good and bad dialogue?",
                                done: false,
                            },
                4:          {
                                img:    cardFrontFiftyFifty_EN,
                                text:      '<b>What is meant by reflecting in a communication situation?</b> <br>'+ 
                                                'A) clarifying the speech by repeating what has been heard <br>' +
                                                'B) getting prepared for a communication situation <br>' +
                                                "C) a way to make sure that one has correctly understood the other person's speech <br>" +
                                                'D) asking the interlocutor to look in the mirror',
                                done: false,
                            },
                5:          {
                                img:    defaultCardFront,
                                text:   '"I think they...”<br/>What can assumptions lead to in communication?' ,
                                done: false,
                            },
                6:          {
                                img:    defaultCardFront,
                                text:   "Have you tried clarifying your message through the concrete examples?",
                                done: false,
                            },
                7:          {
                                img:    defaultCardFront,
                                text:   "Manifestations of empathic presence in a communication situation, any experiences?",
                                done: false,
                            },
                8:          {
                                img:    cardFrontFiftyFifty_EN,
                                text:   '<b>Conducting the conversation means</b><br>' +
                                            'A) making a mini-film <br>' +
                                            'B) taking care of the progress according to the goals <br>' +
                                            'C) taking care of the structural factors of the conversation <br>' +
                                            'D) a way to get your idea across' ,
                                done: false,
                            },

            },

            cardsLibrary:{
                bgImage:            'not-defined',
                cardBackground:     'not-defined',
                booksCount:         8,
                openedBooks: { "1":false, "2":false, "3":false, "4":false, "5":false, "6":false, "7":false, "8":false, },
                books : [ 
                    {
                        id:0, 
                        book:bookIdle, 
                        question:"idle",
                        done: false,
                    },
                    {
                        id:1,
                        place:2, 
                        book:book2, 
                        question:"Think of a time when you were excited about the task at hand. In what way was your enthusiasm reflected in your actions?", 
                        task:taskSmiley,
                        done: false,
                    }, 
                    {
                        id:2,
                        place:3, 
                        book:book3, 
                        question:"Why is communication needed in a work community?", 
                        task:taskPen8,
                        done: false,
                    }, 
                    {
                        id:3, 
                        place:6, 
                        book:book6, 
                        question:"How to discuss information flow problems with another team?", 
                        task:taskPie,
                        done: false,
                    },
                    {
                        id:4, 
                        place:7, 
                        book:book7, 
                        question:"", 
                        task:taskThrone,
                        done: false,
                    }, 
                    {
                        id:5,
                        place:1, 
                        book:book1, 
                        question:"How to act in a situation where a person presents his/her case by shouting?", 
                        task:taskTwoSmiley,
                        done: false,
                    }, 
                    {
                        id:6, 
                        place:4, 
                        book:book4, 
                        question:"Giving and receiving feedback is an important part of professional communication. How could this skill be developed in the work community?", 
                        task:taskPie,
                        done: false,
                    }, 
                    {
                        id:7, 
                        place:5, 
                        book:book5, 
                        question:"", 
                        task: taskSpell_en,
                        done: false,
                    },
                    {
                        id:8, 
                        place:8, 
                        book:book8, 
                        question:"Your colleague is melancholic. He thinks he has failed in an important task. <br />How do you help him deal with it?", 
                        task:taskTwoSmiley,
                        done: false,
                    }
                ],
            },

        
        }
    }    


    games[2] = {
        data :
            {
                gameLang:                       "FI",
                name:                           "VAJO-mallipeli",
                seoImage:                       gameBanner_VAJO,
                text_ObservationInCastle:       "Havaintojani linnassa",
                wordTitle:                      "Valmentava johtaminen – ensipainos",
                wordIntro:                      "Yhteisellä matkalla Tieteentekijöiden linnassa tehtyjä huomioita valmentavasta johtamisesta:",
                wordAction:                     "Luo Word-dokumentti",
    
                version:        2,
                started:        false,
                smileys:        [],
                userComments1:  [],
                userComments2:  [],
                description:    "Kirjoita lyhyt kuvaus",
                startImage:     defaultStartImage,   
                endImage:       defaultEndimage,
                //gameDefaultImage: gameDefaultImage_FI,
                pdfs:           [ 
                                    {
                                        url:startPdf_VAJO,
                                        name: "" // empty name, no pdf
                                    },
                                    {
                                        url:endPdf_VAJO,
                                        name: "Lopetus PDF"
                                    },
                                ],
                slider:         {
                                    questionText:"Mitä mieltä olet väittämästä:", 
                                    minText:"täysin eri mieltä",
                                    maxText:"täysin samaa mieltä",
                                    players: [],
                                    average: 0,
                                    avatarScale : 40,
                                },
                garden:         {
                                    pageTitle: "Havaintojani linnassa:"
                                },                            
    
                cardsLobby:{
                    bgImage:            defaultLobbyBgImage,
                    cardBackground:     defaultcardBackground,
                    imageCount:         8,


                    1:          {
                                    img:    defaultCardFront,
                                    text:   "Perusta vahvuuksiin –<br/>miten vahvuudet ja <br/>onnistumiset ovat mukana <br/>keskusteluissasi?",
                                    done:   false,
                                },
                    2:          {
                                    img:    defaultCardFront,
                                    text:   "Vaali potentiaalia –<br/>keinoja tunnistaa <br/>potentiaali ja <br/>piilevät kyvykkyydet?",
                                    done: false,
                                },
                    3:          {
                                    img:    defaultCardFront,
                                    text:   "Kiinnostu –<br/> toisten ajatuksista<br/> ja näkökulmista, <br/>mihin se voi johtaa?",
                                    done: false,
                                },
                    4:          {
                                    img:    defaultCardFront,
                                    // text:   "Kuuntele –<br/> miten kuuntelu vaikuttaa <br/> vuorovaikutuksessa?",
                                    text:   "Kuuntele –<br/> minkä opin jakaisit <br/> kuuntelemisen taidosta?",
                                    done: false,
                                },
                    5:          {
                                    img:    defaultCardFront,
                                    // text:   "Haasta arvostavasti <br/>itsesi ja muut – <br/>mitä siitä seuraa?",
                                    text:   "Haasta arvostavasti –<br/>kannusta ajattelemaan rohkeammin.<br/>Esimerkkejä?",
                                    done: false,
                                },
                    6:          {
                                    img:    defaultCardFront,
                                    text:   "Kysy –<br/> millaisiin tarkoituksiin<br/> voit käyttää kysymyksiä?",
                                    done: false,
                                },
                    7:          {
                                    img:    defaultCardFront,
                                    text:   "Innosta –<br/> parhaat tapasi <br/> luoda innostusta?",
                                    done: false,
                                },
                    8:          {
                                    img:    CoachManagerLeader,
                                    text:   '' , // no text, only image
                                    done: false,
                                },
    
                },

                rocks:{
                    openedRocks: [false, false, false, false, false, false, false, false, false ], // last is BG
                    texts: [
                        "Miten lisätä<br/>vuorovaikutteisuutta<br/> arjen käytännöissä?",
                        "Miten toimia, kun <br/>havaitsee toimintatapojen<br/> vastaista käytöstä / kohtaa<br/>hankalia tilanteita?", 
                        "Mihin tilanteisiin<br/> sopisi kysymys: <br/>mitä tästä <br/>voisi oppia?", 
                        "Työntekijä kysyy työhön <br/>liittyvää neuvoa, mitä <br/>toimintavaihtoehtoja on?", 
                        "Miten esihenkilö<br/> voi edesauttaa tiimin <br/>jäseniä käyttämään <br/>toistensa vahvuuksia?", 
                        "<br/>", // Tainnutuskivi
                        // "Millaisin keinoin <br/> voi tukea tiimin jäsenten <br/>itseohjautuvuutta<br/> ja oma-aloitteisuutta?", 
                        "Miten esihenkilö<br/> voi ylläpitää omaa<br/>toimintakykyään<br/> muutoksessa?", 
                        'Miten varmistetaan,<br/> että tiimi etenee <br/>kohti tavoitetta?',
                    ],

                    images:[
                        taskVAJO_01,
                        taskVAJO_02,
                        taskVAJO_03,
                        taskVAJO_04,
                        taskVAJO_05_light_bulb,
                        taskVAJO_06,
                        taskVAJO_07,
                        taskVAJO_08,
                    ]
                },
    
            
            }
    }        




    games[3] = {
        data :
            {
                gameLang:                       "FI",
                name:                           "FriCo-VH mallipeli",
                seoImage:                       gameBanner_FI,
                text_ObservationInCastle:       "Havaintojani linnassa",
                wordTitle:                      "Ammatillinen vuorovaikutus – ensipainos",
                wordIntro:                      "Yhteisellä matkalla Tieteentekijöiden linnassa tehtyjä huomioita ammatillisesta vuorovaikutuksesta:",
                wordAction:                     "Luo Word-dokumentti",
    
                version:        2,
                started:        false,
                smileys:        [],
                userComments1:  [],
                userComments2:  [],
                description:    "Kirjoita lyhyt kuvaus",
                startImage:     defaultStartImage,   
                endImage:       defaultEndimage,
                //gameDefaultImage: gameDefaultImage_FI,
                pdfs:           [ 
                                    {
                                        url:startPdf_VH,
                                        name: "Aloitus PDF"
                                    },
                                    {
                                        url:endPdf_VH,
                                        name: "Lopetus PDF"
                                    },
                                ],
    slider:         {
                                    questionText:"Pelituokiossa jaoimme onnistuneesti kokemuksia ja opimme niistä yhdessä", 
                                    minText:"täysin eri mieltä",
                                    maxText:"täysin samaa mieltä",
                                    players: [],
                                    average: 0,
                                    avatarScale : 40,
                                },
                garden:         {
                                    pageTitle: "Havaintojani linnassa:"
                                },                            
    
                cardsLobby:{
                    bgImage:            defaultLobbyBgImage,
                    cardBackground:     defaultcardBackground,
                    imageCount:         8,


        1:          {
                        img:    skill_VH_01,
                        text:   'Mikä seuraavista on alin kuuntelun taso? <br/>Tietäjä, Tuomari, Kuuro, Tulkitsija, Ymmärtäjä?',
                        done: false,
                    },
        2:          {
                        img:    skill_VH_02,
                        text:   '<b>Mitä, missä, miten, kuka, koska, kuinka?</b> <br>'+ 
                                    'Kuvaa kysymisen hyötyjä',
                        done: false,
                    },
        3:          {
                        img:    skill_VH_04,
                        text:   'Mistä tunnistaa hyvän ja huonon vuoropuhelun?',
                        done: false,
                    },
        4:          {
                        img:    skill_VH_06,
                        text:   '<b>Mitä tarkoitetaan peilaamisella vuorovaikutuksessa?</b> <br>'+ 
                                    'A) puheen tarkentamista toistamalla mitä on kuultu <br>' +
                                    'B) laittautumista vuorovaikutustilanteeseen <br>' +
                                    'C) tapaa varmistaa, että on ymmärtänyt oikein toisen puhetta <br>' +
                                    'D) pyytää keskustelukumppania katsomaan peiliin',
                        done: false,
                    },
        5:          {
                        img:    skill_VH_05,
                        text:   '”Luulen, että ne...”<br/>Mihin oletukset voivat vuorovaikutuksessa johtaa?',
                        done: false,
                    },
        6:          {
                        img:    skill_VH_03,
                        text:   'Oletko kokeillut viestien tarkentamista konkreettisten esimerkkien kautta?',
                        done: false,
                    },
        7:          {
                        img:    skill_VH_07,
                        text:   'Empaattisen läsnäolon ilmeneminen vuorovaikutustilanteessa, kokemuksia?',
                        done: false,
                    },
        8:          {
                        img:    skill_VH_08,
                        text:   '<b>Vuorovaikutustilanteen ohjaaminen/johtaminen on </b><br>' +
                                    'A) minielokuvan tekemistä <br>' +
                                    'B) tavoitteiden mukaisesta etenemisestä huolehtimista <br>' +
                                    'C) vuorovaikutustilanteen rakenteellisista tekijöistä huolehtimista <br>' +
                                    'D) tapa saada oma ajatus läpi' ,
                        done: false,
                    },
    
                },

        cardsLibrary:{
                    bgImage:            'not-defined',
                    cardBackground:     'not-defined',
                    booksCount:         8,
                    openedBooks: { "1":false, "2":false, "3":false, "4":false, "5":false, "6":false, "7":false, "8":false, },
                    books : [ 
                        {
                            id:0, 
                            book:bookIdle, 
                            question:"idle",
                            done: false,
                        },
                        {
                            id:1,
                            place:2, 
                            book:book2, 
                            question:"Mieti tilanne, jossa olet ollut innostunut käsillä olevasta tehtävästä. Millä tavalla innostuneisuus näkyi toiminnassasi?", 
                            task:taskSmiley,
                            done: false,
                        }, 
                        {
                            id:2,
                            place:3, 
                            book:book3, 
                            question:"Mihin työyhteisössä tarvitaan vuorovaikutusta?", 
                            task:taskPen8,
                            done: false,
                        }, 
                        {
                            id:3, 
                            place:6, 
                            book:book6, 
                            question:"Miten ottaa toisen tiimin kanssa puheeksi tiedonkulun ongelmia?", 
                            task:taskPie,
                            done: false,
                        },
                        {
                            id:4, 
                            place:7, 
                            book:book7, 
                            question:"", 
                            task:taskThrone,
                            done: false,
                        }, 
                        {
                            id:5,
                            place:1, 
                            book:book1, 
                            question:"Millaisin keinoin voitte päästä eteenpäin jumiutuneessa keskustelussa, jossa ilmenee erilaisia näkemyksiä?", 
                            task:taskTwoSmiley,
                            done: false,
                        }, 
                        {
                            id:6, 
                            place:4, 
                            book:book4, 
                            question:"Palautteen antaminen ja vastaanottaminen ovat tärkeä osa ammatillista vuorovaikutusta. Miten tätä taitoa voisi työyhteisössä kehittää?", 
                            task:taskPie,
                            done: false,
                        }, 
                        {
                            id:7, 
                            place:5, 
                            book:book5, 
                            question:"", 
                            task: taskSpell,
                            done: false,
                        },
                        {
                            id:8, 
                            place:8, 
                            book:book8, 
                            question:"Kollegasi on alakuloinen. Hän on mielestään epäonnistunut tärkeässä asiassa. <br />Miten autat häntä käsittelemään asiaa?", 
                            task:taskTwoSmiley,
                            done: false,
                        }
                    ],
                },

            
            }
    }        



    games[4] = {
        data :
            {
                gameLang:                       "FI",
                name:                           "FriCo-mallipeli OSAO",
                seoImage:                       gameBanner_FI,
                text_ObservationInCastle:       "Havaintojani linnassa",
                wordTitle:                      "Ammatillinen vuorovaikutus – ensipainos",
                wordIntro:                      "Yhteisellä matkalla Tieteentekijöiden linnassa tehtyjä huomioita ammatillisesta vuorovaikutuksesta:",
                wordAction:                     "Luo Word-dokumentti",
    
                version:        2,
                started:        false,
                smileys:        [],
                userComments1:  [],
                userComments2:  [],
                description:    "Kirjoita lyhyt kuvaus",
                startImage:     defaultStartImage,   
                endImage:       defaultEndimage,
                //gameDefaultImage: gameDefaultImage_FI,
                pdfs:           [ 
                                    {
                                        url:startPdf_VH,
                                        name: "Aloitus PDF"
                                    },
                                    {
                                        url:endPdf_VH,
                                        name: "Lopetus PDF"
                                    },
                ],
                slider:         {
                                    questionText:"Mitä mieltä olet väittämästä:", 
                                    minText:"täysin eri mieltä",
                                    maxText:"täysin samaa mieltä",
                                    players: [],
                                    average: 0,
                                    avatarScale : 40,
                                },
                garden:         {
                                    pageTitle: "Havaintojani linnassa:"
                                },                            
    
                // cardsLobby:{
                //     bgImage:            defaultLobbyBgImage,
                //     cardBackground:     defaultcardBackground,
                //     imageCount:         8,
                
                //     1:          {
                //                     img:    defaultCardFront,
                //                     text:   'Mikä seuraavista on alin kuuntelun taso? <br/>Tietäjä, Tuomari, Kuuro, Tulkitsija, Ymmärtäjä?',
                //                     done: false,
                //                 },
                //     2:          {
                //                     img:    defaultCardFront,
                //                     text:   '<b>Kysyminen vuorovaikutustilanteissa:</b> <br>'+ 
                //                                 '• osoittaa kiinnostusta <br>' +
                //                                 '• lisää ymmärrystä<br>' +
                //                                 '• mitä muita hyötyjä olet havainnut?',
                //                     done: false,
                //                 },
                //     3:          {
                //                     img:    defaultCardFront,
                //                     text:   'Mistä tunnistaa hyvän ja huonon vuoropuhelun?',
                //                     done: false,
                //                 },
                //     4:          {
                //                     img:    cardFrontFiftyFifty,
                //                     text:   '<b>Mitä tarkoitetaan peilaamisella vuorovaikutuksessa?</b> <br>'+ 
                //                                 'A) puheen tarkentamista toistamalla mitä on kuultu <br>' +
                //                                 'B) laittautumista vuorovaikutustilanteeseen <br>' +
                //                                 'C) tapaa varmistaa, että on ymmärtänyt oikein toisen puhetta <br>' +
                //                                 'D) pyytää keskustelukumppania katsomaan peiliin',
                //                     done: false,
                //                 },
                //     5:          {
                //                     img:    defaultCardFront,
                //                     text:   '”Luulen, että ne...”<br/>Mihin oletukset voivat vuorovaikutuksessa johtaa?',
                //                     done: false,
                //                 },
                //     6:          {
                //                     img:    defaultCardFront,
                //                     text:   'Oletko kokeillut viestien tarkentamista konkreettisten esimerkkien kautta?',
                //                     done: false,
                //                 },
                //     7:          {
                //                     img:    defaultCardFront,
                //                     text:   'Empaattisen läsnäolon ilmeneminen vuorovaikutustilanteessa, kokemuksia?',
                //                     done: false,
                //                 },
                //     8:          {
                //                     img:    cardFrontFiftyFifty,
                //                     text:   '<b>Vuorovaikutustilanteen ohjaaminen on </b><br>' +
                //                                 'A) minielokuvan tekemistä <br>' +
                //                                 'B) tavoitteiden mukaisesta etenemisestä huolehtimista <br>' +
                //                                 'C) vuorovaikutustilanteen rakenteellisista tekijöistä huolehtimista <br>' +
                //                                 'D) tapa saada oma ajatus läpi' ,
                //                     done: false,
                //                 },
    
                // },
                
                // Above questions replaced on 7.2.2024 by the following:
                cardsLobby:{
                    bgImage:            defaultLobbyBgImage,
                    cardBackground:     defaultcardBackground,
                    imageCount:         8,
    
    
                    1:          {
                                    img:    skill_VH_01,
                                    text:   'Mikä seuraavista on alin kuuntelun taso? <br/>Tietäjä, Tuomari, Kuuro, Tulkitsija, Ymmärtäjä?',
                                    done: false,
                                },
                    2:          {
                                    img:    skill_VH_02,
                                    text:   '<b>Mitä, missä, miten, kuka, koska, kuinka?</b> <br>'+ 
                                                'Kuvaa kysymisen hyötyjä',
                                    done: false,
                                },
                    3:          {
                                    img:    skill_VH_04,
                                    text:   'Mistä tunnistaa hyvän ja huonon vuoropuhelun?',
                                    done: false,
                                },
                    4:          {
                                    img:    skill_VH_06,
                                    text:   '<b>Mitä tarkoitetaan peilaamisella vuorovaikutuksessa?</b> <br>'+ 
                                                'A) puheen tarkentamista toistamalla mitä on kuultu <br>' +
                                                'B) laittautumista vuorovaikutustilanteeseen <br>' +
                                                'C) tapaa varmistaa, että on ymmärtänyt oikein toisen puhetta <br>' +
                                                'D) pyytää keskustelukumppania katsomaan peiliin',
                                    done: false,
                                },
                    5:          {
                                    img:    skill_VH_05,
                                    text:   '”Luulen, että ne...”<br/>Mihin oletukset voivat vuorovaikutuksessa johtaa?',
                                    done: false,
                                },
                    6:          {
                                    img:    skill_VH_03,
                                    text:   'Oletko kokeillut viestien tarkentamista konkreettisten esimerkkien kautta?',
                                    done: false,
                                },
                    7:          {
                                    img:    skill_VH_07,
                                    text:   'Empaattisen läsnäolon ilmeneminen vuorovaikutustilanteessa, kokemuksia?',
                                    done: false,
                                },
                    8:          {
                                    img:    skill_VH_08,
                                    text:   '<b>Vuorovaikutustilanteen ohjaaminen/johtaminen on </b><br>' +
                                                'A) minielokuvan tekemistä <br>' +
                                                'B) tavoitteiden mukaisesta etenemisestä huolehtimista <br>' +
                                                'C) vuorovaikutustilanteen rakenteellisista tekijöistä huolehtimista <br>' +
                                                'D) tapa saada oma ajatus läpi' ,
                                    done: false,
                    },
    
                },
    
    
                cardsLibrary:{
                    bgImage:            'not-defined',
                    cardBackground:     'not-defined',
                    booksCount:         8,
                    openedBooks: { "1":false, "2":false, "3":false, "4":false, "5":false, "6":false, "7":false, "8":false, },
                    books : [ 
                        {
                            id:0, 
                            book:bookIdle, 
                            question:"idle",
                            done: false,
                        },
                        {
                            id:1,
                            place:2, 
                            book:book2, 
                            question:"Mieti tilanne, jossa olet ollut innostunut käsillä olevasta tehtävästä. Millä tavalla innostuneisuus näkyi toiminnassasi?", 
                            task:taskSmiley,
                            done: false,
                        }, 
                        {
                            id:2,
                            place:3, 
                            book:book3, 
                            question:"Mihin työyhteisössä tarvitaan vuorovaikutusta?", 
                            task:taskPen8,
                            done: false,
                        }, 
                        {
                            id:3, 
                            place:6, 
                            book:book6, 
                            question:"Miten ottaa toisen tiimin kanssa puheeksi tiedonkulun ongelmia?", 
                            task:taskPie,
                            done: false,
                        },
                        {
                            id:4, 
                            place:7, 
                            book:book7, 
                            question:"", 
                            task:taskThrone,
                            done: false,
                        }, 
                        {
                            id:5,
                            place:1, 
                            book:book1, 
                            question:"Millaisia huolia tai toiveita keskittämiseen liittyy?  <br />Miten niitä voisi käsitellä?", 
                            task:book_hart_arrows,
                            done: false,
                        }, 
                        {
                            id:6, 
                            place:4, 
                            book:book4, 
                            question:"Palautteen antaminen ja vastaanottaminen ovat tärkeä osa ammatillista vuorovaikutusta. Miten tätä taitoa voisi työyhteisössä kehittää?", 
                            task:taskPie,
                            done: false,
                        }, 
                        {
                            id:7, 
                            place:5, 
                            book:book5, 
                            question:"", 
                            task: taskSpell,
                            done: false,
                        },
                        {
                            id:8, 
                            place:8, 
                            book:book8, 
                            question:"Kollegasi on alakuloinen. Hän on mielestään epäonnistunut tärkeässä asiassa. <br />Miten autat häntä käsittelemään asiaa?", 
                            task:taskTwoSmiley,
                            done: false,
                        }
                    ],
                },
    
            
            }
    }    
    

export const getDefaultGameData = (option=0)=>{
    
    return games[option].data;

}
